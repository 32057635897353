<template>
  <b-container fluid>
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h3 class="card-title">Rechnungskorrekturen</h3>
        </div>
        <div class="card-body">
          <div class="row pb-2">
            <div class="col-md-1">
              Von:
            </div>
            <div class="col-md-3">
              <input type="date" class="form-control form-control-sm" v-model="params.from">
            </div>
            <div class="col-md-1">
              Bis:
            </div>
            <div class="col-md-3">
              <input type="date" class="form-control form-control-sm" v-model="params.to">
            </div>
            <div class="col-md-3">
              <button class="btn btn-warning btn-sm" @click="exportStornos()">
                <i class="fas fa-export"></i> Exportieren
              </button>
            </div>
          </div>
          <table class="table table-striped table-bordered">
            <thead>
              <td>Storno-Nr.</td>
              <td>Datum</td>
              <td>Betrag</td>
              <td>Status</td>
              <td>Aktion</td>
            </thead>

            <tbody>
              <tr v-for="storno in stornos" :key="storno.id">
                <td>
                  <template v-if="storno.correction != null">{{ storno.correction.number }}</template>
                  <template v-else>- none -</template>
                </td>
                <td>{{ storno.created_at | formatDateTime }}</td>
                <td>{{ storno.invoice_amount | toCurrency}}</td>
                <td>{{ storno.status.description }}</td>
                <td>
                  <router-link class="btn btn-success btn-sm" :to="{name: 'stornos.details', params: {id: storno.id}}" v-if="$auth.check('stornos.show')"><i class="fas fa-eye"></i></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  </b-container>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Stornos',
  title: 'Rechnungskorrekturen',

  data(){
    return {
      stornos: [],
      params: {
        from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      }
    }
  },

  methods: {
    getStornos(){
      this.$Progress.start();
      this.axios
          .get("/stornos")
          .then((response) => {
              this.stornos = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },
    exportStornos()
    {
      this.$Progress.start();
      this.axios
        .get("/storno/export", {
          responseType: 'blob',
          // params: {
          //   ...this.params
          // },
        })
        .then((response) => {
          this.$Progress.finish();
          const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download",  'Stornos_' + this.params.from +'_zum_' + this.params.to +'.xlsx');
          document.body.appendChild(link);

          // Dynamicall click the a link element
          // This will download the csv file
          link.click();
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Beim Exportieren ist etwas schief gelaufen'
          })
          this.$Progress.fail();
        });
    },
  },

  created(){
    this.getStornos();
  }

}
</script>

<style>

</style>